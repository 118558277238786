<template>
    <st-section :header="$t('STAFF.SECTION_TITLE')">
        <template #body>
            <form class="form" novalidate="novalidate" id="st_staff_form">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label> {{ fields.last_name.label }} </label>
                            <st-input-text
                                v-model="model[fields.last_name.name]"
                                :ref="fields.last_name.name"
                                :name="fields.last_name.name"
                                disabled
                            />
                        </div>
                        <div class="form-group">
                            <label> {{ fields.first_name.label }} </label>
                            <st-input-text
                                v-model="model[fields.first_name.name]"
                                :ref="fields.first_name.name"
                                :name="fields.first_name.name"
                                disabled
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.function.label }}
                            </label>
                            <st-input-text
                                v-model="model[fields.function.name]"
                                :ref="fields.function.name"
                                :name="fields.function.name"
                                disabled
                            />
                        </div>
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label>
                                {{ fields.identification_number.label }} 
                            </label>
                            <st-input-text
                                v-model="model[fields.identification_number.name]"
                                :ref="fields.identification_number.name"
                                :name="fields.identification_number.name"
                                disabled
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.id_released_by.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.id_released_by.name]"
                                :ref="fields.id_released_by.name"
                                :name="fields.id_released_by.name"
                                :disabled="viewMode"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.id_expiration_date.label }} *
                            </label>
                            <st-datepicker
                                v-model="model[fields.id_expiration_date.name]"
                                :ref="fields.id_expiration_date.name"
                                :name="fields.id_expiration_date.name"
                                :disabled="viewMode"
                                :min="min"
                            />
                        </div>
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="form-group">
                            <label>
                                {{ fields.organisation.label }}
                            </label>
                            <st-input-text
                                v-model="model[fields.organisation.name]"
                                :ref="fields.organisation.name"
                                :name="fields.organisation.name"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </st-section>
</template>

<script>
import { mapGetters } from "vuex";
import { StaffModel } from "@/modules/control/models/staff-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { idCard } from '@/shared/utils/id-card';

const { fields } = StaffModel;

const formSchema = new FormSchema([
    fields.id,
    fields.first_name,
    fields.last_name,
    fields.identification_number,
    fields.id_released_by,
    fields.function,
    fields.organisation,
    fields.id_expiration_date,
    fields.organisation_id
]);

export default {
    name: "StaffForm",
    data() {
        return {
            model: null,
            rules: formSchema.rules(),
            fields: fields,
            min: idCard.expirationDate,
            fv: null,
        };
    },
    props: {
        viewMode: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            accountDetails: "auth/accountDetails",
            record: "control/form/record",
        }),
        userData() {
            const data = { ...this.accountDetails, ...this.currentUser };
            data.function = data.functions?.join(',');
            return data;
        },
    },
    created() {
        const record = this.record?.form?.staff ?? this.userData;
        this.model = formSchema.initialValues(record);

    },
    mounted() {
        this.fv = createFormValidation("st_staff_form", this.rules);
    },
};
</script>
